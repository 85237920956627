<template>
<div id="app">
  <router-view />
</div>
</template>


<style scoped>
#app{
  width: 100%;
  height: auto;

}

  @media only screen and (min-width: 436px) {
    #app{
    widows: 100%;
    height: auto;
    }
  }

</style>